import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useInterval, useMediaQuery } from 'usehooks-ts';
import { AditionInitialization } from '@components/atoms/Adition/AditionInitialization';
import { SubscriptionOverview, SubscriptionOverviewOffer } from '@src/lib/graphql/generated';
import { adPositions } from '@lib/adition/adPositions';
import { AboSelection } from '../AboSelection/AboSelection';

export const AboSelections = ({ offers, more }: SubscriptionOverview) => {
  const [selectedAbo, setSelectedAbo] = useState<SubscriptionOverviewOffer>(offers.find((abo) => abo.favorite));
  const [isHovered, setIsHovered] = useState(false);
  const screenIsXl = useMediaQuery('(min-width: 1280px)');

  useInterval(
    () => {
      const currentAboIndex = offers.findIndex((abo) => abo.id === selectedAbo?.id);
      const nextAboIndex = (currentAboIndex + 1) % offers.length;
      setSelectedAbo(offers[nextAboIndex]);
    },
    isHovered || !screenIsXl ? null : 3000,
  );

  if (screenIsXl && !selectedAbo) {
    setSelectedAbo(offers[0]);
  }

  function toggleSelectedAbo(abo: SubscriptionOverviewOffer) {
    if (abo.id === selectedAbo?.id && !screenIsXl) {
      setSelectedAbo(null);
      return;
    }
    setSelectedAbo(abo);
  }

  return (
    <div className="mx-auto mb-32 max-w-screen-md xl:max-w-screen-2xl">
      <AditionInitialization tags={adPositions} />
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={twMerge('flex flex-col gap-4 xl:flex-row')}
      >
        <div className="top-8 h-fit xl:sticky xl:w-1/4 xl:min-w-80">
          <div className="flex w-full flex-col gap-4">
            {offers.slice(0, Math.ceil(offers.length / 2)).map((abo) => (
              <AboSelection
                key={abo.id}
                abo={abo}
                onClick={() => toggleSelectedAbo(abo)}
                active={abo.id === selectedAbo?.id}
                small
              />
            ))}
          </div>
        </div>
        <AboSelection
          active
          abo={selectedAbo}
          classProps={{
            root: 'row-span-full hidden xl:block flex-grow !p-8',
            tagline: 'hidden',
            title: 'lg:text-4xl',
            favorite: 'xl:mb-4',
          }}
        />
        <div className="top-8 h-fit xl:sticky xl:w-1/4 xl:min-w-80">
          <div className="flex w-full flex-col gap-4">
            {offers.slice(Math.ceil(offers.length / 2)).map((abo) => (
              <AboSelection
                key={abo.id}
                abo={abo}
                onClick={() => toggleSelectedAbo(abo)}
                active={abo.id === selectedAbo?.id}
                small
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mt-12 text-center prose-a:underline" dangerouslySetInnerHTML={{ __html: more }} />
    </div>
  );
};
