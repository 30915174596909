import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Button } from '@components/atoms/Button/Button';
import { CircleCounter } from '@components/atoms/CircleCounter/CircleCounter';
import { Title } from '@components/atoms/Title';
import { providers } from '@utils/providers';
import { sortedChannels } from '@utils/sortedChannels';
import { Slide } from '@lib/schema';
import { IconNext } from '@assets/icon-next';
import { IconPrev } from '@assets/icon-prev';

export interface HeroSlideshowProps extends PropsWithClassProps<'Slide' | 'Img' | 'ImageCard' | 'ImageText'> {
  slides: Slide[];
}

const CDN_URL = process.env.NEXT_PUBLIC_CDN ?? '';
const slideTime = 8000;
const initialSlideDelay = slideTime / 2;

export const HeroSlideshow = ({ slides }: HeroSlideshowProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [fetchedSlideData, setFetchedSlideData] = useState([]);
  const isInitialSlide = useRef(true);

  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const changeSlide = React.useCallback(
    (count) => {
      setActiveSlide((prev) =>
        prev + count > slides.length - 1 ? 0 : prev + count < 0 ? slides.length - 1 : prev + count,
      );
      isInitialSlide.current = false;
    },
    [slides.length],
  );

  useEffect(() => {
    slides.forEach((e) => {
      setFetchedSlideData((prev) =>
        !prev?.find((p) => p?.data?.id === e.entityData?.id) ? [...prev, e?.entityData] : prev,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => changeSlide(1), slideTime + (isInitialSlide.current ? initialSlideDelay : 0));
    return () => {
      resetTimeout();
    };
  }, [activeSlide, changeSlide]);

  const slideData = fetchedSlideData.length > 0 ? fetchedSlideData : slides.map((e) => e?.entityData);
  const slidesStyle = { transform: `translateX(-${activeSlide * 100}%)` };

  function getSlideImage(entityId) {
    const data = slideData.find((e) => e?.id === entityId);
    return data?.backdrop_image?.file?.path || data?.cover_image?.file?.path;
  }

  function getEntitySlideLink(entityId) {
    if (!entityId) return '/';
    const data = slideData.find((e) => e?.id === entityId);
    if (!data) return '/';
    const linkTypes = {
      Movie: '/filme/',
      Show: '/serien/',
    };

    return linkTypes[data?.__typename] + (data?.slug || data?.id);
  }

  return (
    <section className="relative mb-[-12vh] min-h-[90vh] w-full overflow-hidden pb-[8vh] pt-[4vh] sm:mb-[-25vh] sm:pb-[25vh] sm:pt-[12vh] portrait-giant:!mb-0 portrait-giant:!h-[640px] portrait-giant:!min-h-[640px] portrait-giant:!pt-40 portrait-giant:pb-20">
      <div className="absolute inset-0 flex transition-transform duration-700" style={slidesStyle}>
        {slides.map((slide, idx) => (
          <div className="relative size-full flex-none overflow-hidden" key={idx}>
            {slide.__typename === 'HeroSlideServices' ? (
              <MovingSlideBackground priority={idx === 0} />
            ) : (
              getSlideImage(slide?.entity?.id) && (
                <Image
                  src={getSlideImage(slide?.entity?.id)}
                  alt={slide?.entity?.title}
                  fill
                  classProps={{ root: 'object-cover' }}
                  copyright=""
                  loading={idx === 0 ? 'eager' : 'lazy'}
                  sizes="(max-width: 480px) 100vh, (max-width: 1080px) 100vw, 1080px"
                  priority={idx === 0}
                />
              )
            )}
          </div>
        ))}
      </div>
      {/* GRADIENTS */}
      <div className={'pointer-events-none absolute inset-0 bg-gradient-to-r from-black/30 via-black/20 to-black/40'} />
      <div className={'pointer-events-none absolute inset-0 bg-gradient-to-b from-black/10 via-black/50 to-black'} />

      <div className="pointer-events-auto relative inset-0 grid items-center gap-8 p-8 lg:grid-cols-[auto,1fr,auto] lg:gap-16 lg:p-16 xl:p-20">
        <button
          role="button"
          aria-label="slide backward"
          className="hidden size-16 cursor-pointer place-items-center rounded-full border border-gray-650/65 bg-gray-820/70 text-gray-650/65 transition-all duration-300 hover:border-white/75 hover:text-white/75 lg:grid"
          id="prev"
          onClick={() => {
            changeSlide(-1);
          }}
        >
          <IconPrev
            classProps={{
              root: 'size-6',
            }}
          />
        </button>
        <div className="w-full min-w-0">
          {/* ### CONTENT START ### */}
          {/* SERVICES */}
          <div className="flex w-full overflow-hidden">
            {slides.map((slide, idx) => (
              <div
                key={idx}
                className={twMerge(
                  'w-full flex-none translate-y-4 opacity-0 transition-all duration-300',
                  idx !== 0 && 'ml-[-100%]',
                  activeSlide === idx && 'translate-y-0 opacity-100',
                )}
              >
                <SlideServices serviceIds={slide?.services} />
              </div>
            ))}
          </div>

          <div className="mt-3 grid gap-6 sm:mt-8 md:grid-cols-2 xl:grid-cols-[3fr,2fr]">
            <div className="min-w-0">
              {/* TITLE */}
              <div className="flex w-full items-center overflow-hidden">
                {slides.map((slide, idx) => (
                  <div
                    key={idx}
                    className={twMerge(
                      'w-full flex-none opacity-0 transition-opacity duration-300',
                      idx !== 0 && 'ml-[-100%]',
                      idx === activeSlide && 'opacity-100',
                    )}
                  >
                    <div className="mb-4 font-herokid text-2xl text-primary">
                      {slide.red_label} <span className="text-white">{slide.tagline}</span>
                    </div>
                    <Title
                      classProps={{
                        heading:
                          'text-4xl font-semibold leading-header font-herokid lg:text-5xl w-full max-w-[520px] md:max-w-full',
                      }}
                      level={idx === 0 ? 1 : 2}
                    >
                      {slide.title}
                    </Title>
                  </div>
                ))}
              </div>

              {/* COUNTER */}
              <div className="mt-6 flex gap-5">
                {slides.map((slide, index) => (
                  <span
                    className="cursor-pointer"
                    key={index}
                    onClick={() => {
                      setActiveSlide(index);
                    }}
                  >
                    <CircleCounter
                      size={30}
                      strokeWidth={1.8}
                      timeout={isInitialSlide ? initialSlideDelay : 0}
                      duration={slideTime}
                      active={index === activeSlide}
                    />
                  </span>
                ))}
              </div>
            </div>

            {/* TEXT / BUTTON */}
            <div className="flex w-full min-w-0 overflow-hidden lg:items-center">
              {slides.map((slide, idx) => (
                <div
                  key={idx}
                  className={twMerge(
                    'pointer-events-none w-full flex-none opacity-0 transition-opacity duration-300',
                    idx !== 0 && 'ml-[-100%]',
                    idx === activeSlide && 'pointer-events-auto z-10 opacity-100',
                  )}
                >
                  <div
                    className="max-w-[320px] text-base leading-body"
                    dangerouslySetInnerHTML={{ __html: slide.text }}
                  />
                  <Button
                    title={slide?.button_title || 'Mehr Info'}
                    hasAction={false}
                    link={
                      slide.__typename === 'HeroSlideEntity'
                        ? getEntitySlideLink(slide?.entity?.id)
                        : slide?.button_link || '/'
                    }
                    classProps={{ button: 'inline-block pointer-events-auto' }}
                    buttonStyle="primary"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* ### CONTENT END ### */}
        </div>
        <button
          role="button"
          aria-label="slide forward"
          className="hidden size-16 cursor-pointer place-items-center rounded-full border border-gray-650/65 bg-gray-820/70 text-gray-650/65 transition-all duration-300 hover:border-white/75 hover:text-white/75 lg:grid"
          id="next"
          onClick={() => {
            changeSlide(1);
          }}
        >
          <IconNext
            classProps={{
              root: 'size-6',
            }}
          />
        </button>
      </div>
    </section>
  );
};

const MovingSlideBackground = ({ priority = false }) => {
  return (
    <div className="size-full origin-center scale-150 object-cover">
      <div className="relative grid size-full">
        <Image
          src={CDN_URL + '/cinema/covers.webp'}
          alt={'Movie cover'}
          fill
          copyright=""
          classProps={{ root: 'object-cover opacity-70', container: 'w-full h-full' }}
          sizes="(min-width: 468px) 200vh, 200vw"
          className=""
          priority={priority}
        />
      </div>
    </div>
  );
};

interface SlideServicesProps {
  serviceIds: string[];
}

const SlideServices = ({ serviceIds }: SlideServicesProps) => {
  const [services, setServices] = useState(null);

  useEffect(() => {
    const services = [];
    serviceIds?.forEach((e) => {
      const service = sortedChannels.find((c) => c.id === e);
      if (service) {
        if (service?.localLogo) {
          services.push(service);
        }
      } else {
        const service = providers.find((c) => c.id === e);
        if (service?.logo) {
          services.push(service);
        }
      }
    });
    setServices(services);
  }, [serviceIds]);
  return (
    <div className="mx-auto flex h-48 w-full min-w-0 max-w-screen-xs flex-wrap content-end items-end justify-center gap-6 pb-6 md:h-auto lg:max-w-none lg:justify-start lg:justify-items-start lg:gap-10 xl:gap-12">
      {services?.map((service, index) => (
        <div
          className="relative inline-block h-8 w-24 min-w-[5em] lg:h-10 lg:w-32"
          key={service?.id ?? `service-${index}`}
        >
          <Image
            src={service?.localLogo || service?.logo}
            alt={`Senderlogo ${index + 1}`}
            fill
            copyright=""
            loading="eager"
          />
        </div>
      ))}
    </div>
  );
};
