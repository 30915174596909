import React from 'react';
import Head from 'next/head';
import { AditionTagWithFallback } from '@vgn-medien-holding/vgn-fe-components';
import { getCookie } from 'cookies-next';
import { twMerge } from 'tailwind-merge';
import { RESSORT_ARTICLES_PER_LOAD } from 'tvm-config';
import { AditionInitialization } from '@components/atoms/Adition/AditionInitialization';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { Oewa } from '@components/atoms/Oewa/Oewa';
import { Title } from '@components/atoms/Title';
import RessortPage from '@components/organisms/PageContents/RessortPage';
import { SubscriptionOverviewPage } from '@components/organisms/PageContents/SubscriptionOverviewPage';
import * as PageElements from '@components/organisms/PageElements/PageElements';
import { Article as ArticleLayout } from '@components/templates/layouts/Article';
import { DefaultNoSpacing } from '@components/templates/layouts/DefaultNoSpacing';
import { DefaultOnlyLogo } from '@components/templates/layouts/DefaultOnlyLogo';
import { Empty } from '@components/templates/layouts/Empty';
import {
  GetAllArticlesPaginatedDocument,
  GetAllRessortsDocument,
  GetBySlugFullDocument,
} from '@src/lib/graphql/generated';
import { adPositions } from '@lib/adition/adPositions';
import { fetchExtraPageData } from '@lib/graphql/helpers/fetchExtraPageData';
import { clearCache, ssrCache, ssrQuery } from '@lib/graphql/urql';
import { useTagManager } from '@lib/hooks/useTagManager';
import { handleRedirect } from '@lib/redirects/redirect';

function Page({ data }) {
  if (data.__typename === 'SubscriptionOverview') {
    return (
      <DefaultOnlyLogo>
        <SubscriptionOverviewPage data={data} />
      </DefaultOnlyLogo>
    );
  }

  return data.isRessort ? (
    <ArticleLayout>
      <RessortPage data={data} />
    </ArticleLayout>
  ) : (
    <DefaultNoSpacing>
      <DefaultPage data={data} />
    </DefaultNoSpacing>
  );
}

export const DefaultPage = ({ data }) => {
  const { title, show_title, pageContent, oewa } = data;

  const containerStyle = twMerge(
    'text-gray-600 dark',
    pageContent?.[0]?.__typename === 'PageContentElementHero' ||
      pageContent?.[0]?.__typename === 'PageContentElementHeroImages'
      ? ''
      : 'pt-10 mt-24',
  );

  useTagManager({
    event: 'metadata',
    loginStatus: 'nicht eingeloggt',
    seitentyp: 'Seite',
    titel: title,
    assetID: oewa?.id,
    aktualisierungsdatum: data?.updated_at,
    erstellungsdatum: data?.created_at,
    oewaSeitenkontingent: oewa?.page_key || 'RedCont',
    oewaSubkontingent: oewa?.category_key || 'KulturUndFreizeit',
    oewaProfilingkontingent: oewa?.profile_key || 'FilmUndKino',
    cookieAlert: getCookie('OptanonConsent'),
  });

  return (
    <>
      <Oewa
        categoryKey={oewa?.page_key || 'RedCont'}
        pageKey={oewa?.category_key || 'KulturUndFreizeit'}
        profileKey={oewa?.profile_key || 'FilmUndKino'}
      />
      <AditionInitialization tags={adPositions} data={data} />
      <Head>{Meta(data?.metadata)}</Head>
      <div className={containerStyle}>
        {show_title && title && (
          <Title level={1} classProps={{ heading: 'text-center mb-6 dark:text-white' }}>
            {title}
          </Title>
        )}
        {pageContent && <PageBody pageContent={pageContent} />}
      </div>
    </>
  );
};

function PageBody({ pageContent }) {
  let ad_count = 0;
  return (
    <div className="space-y-16 text-white md:space-y-32">
      {pageContent.map((element, idx) => {
        return (
          <div key={idx}>
            {idx > 1 && idx % 3 === 0 && ad_count < (idx > 9 ? 3 : 2) && (
              <div className="my-8 grid place-items-center lg:-mt-20 lg:mb-4">
                <AditionTagWithFallback
                  tag={adPositions[2]}
                  tagCount={ad_count}
                  fallback={adPositions[20 + ad_count++]}
                  breakpoint={'lg'}
                />
              </div>
            )}
            {element.headline &&
              element?.__typename !== 'PageContentElementHeroImages' &&
              element?.__typename !== 'PageContentElementEntityAd' && (
                <Title level={element.headline_level} classProps={{ heading: 'pb-4 dark:text-white' }}>
                  {element.headline}
                </Title>
              )}
            {!!PageElements?.[element.__typename] && PageElements?.[element.__typename]?.(element, idx)}
          </div>
        );
      })}

      <div className="mb-4 grid place-items-center lg:-mt-20">
        <AditionTagWithFallback tag={adPositions[2]} fallback={adPositions[23]} breakpoint={'lg'} tagCount={ad_count} />
      </div>
    </div>
  );
}

export async function getServerSideProps({ params, req, res }) {
  res.setHeader('Cache-Control', 'public, s-maxage=86400, stale-while-revalidate=240');

  clearCache();

  const { data: ressortsData } = await ssrQuery({
    query: GetAllRessortsDocument,
    variables: { portal: 'tvmedia' },
  });
  const ressorts = ressortsData?.ressorts;

  const activeRessort = ressorts.find((e) => e.slug === params.categoryOrPage);
  const isRessort = !!activeRessort;

  let data;
  if (isRessort) {
    data = { ...data, isRessort, activeRessort, ressorts };

    await ssrQuery({
      query: GetAllArticlesPaginatedDocument,
      variables: {
        portal: 'tvmedia',
        ressort: activeRessort?.id,
        first: RESSORT_ARTICLES_PER_LOAD,
        page: 1,
        orderBy: 'updated_at',
        orderDirection: 'DESC',
      },
    });
  } else {
    // TVMS-76: Handle invalid urls
    const slug = req.url.startsWith('/index.html?/') ? req.url : params.categoryOrPage;

    try {
      const { data: pageData } = await ssrQuery({
        query: GetBySlugFullDocument,
        variables: {
          portal: 'tvmedia',
          slug: slug,
          coverImageWidth: 3840,
          coverImageHeight: 2160,
          coverImageFit: 'crop_focal',
        },
      });
      const page = pageData?.findBySlug;

      if (!page) return { notFound: true };

      data = {
        ...data,
        ...page,
        pageContent: page?.__typename === 'Page' ? await fetchExtraPageData(page?.pageContent) : null,
      };
    } catch (err) {
      return { notFound: true };
    }
  }

  // Handle redirect result
  if (data?.redirect_type || data?.metadata?.redirect) {
    return handleRedirect(
      data?.redirect_type,
      data?.redirect_target != null ? data?.redirect_target : data?.metadata?.redirect,
    );
  }

  const props = {
    data,
    urqlState: null,
  };

  if (isRessort) {
    props.urqlState = ssrCache.extractData();
  }

  return {
    props,
  };
}

Page.PageLayout = Empty;

export default Page;
